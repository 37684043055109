enum stppBaseUrlLookup {
  local = 'http://localhost:3001',
  dev = 'https://dev-showingtimeplus-public.dna-compute-qa.zg-int.net/pro',
  qa = '',
  stage = '',
  test = '',
  prod = '',
}

export default stppBaseUrlLookup;
