import { ReactNode } from 'react';
import PageContentError from '~/components/layout/PageContentError';
import { useNavigate } from 'react-router-dom';

type Props = {
  link: string;
  actionText: string | ReactNode;
  message: string | ReactNode;
  subMessage: string | ReactNode;
};

function NotFound({ link, actionText, message, subMessage }: Props) {
  const navigate = useNavigate();

  return (
    <PageContentError
      action={() => navigate(link)}
      actionText={actionText}
      message={message}
      subMessage={subMessage}
    />
  );
}

export default NotFound;
