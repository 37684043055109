import { NavigateFunction } from 'react-router-dom';
import {
  PROFILE_ID_HEADER,
  makeVegaRequest,
  makeJSONAPIRequest,
} from '~/lib/api/network';
import config from '~/config';
import { LoopTemplateResource } from '~/store/features/api/resources/loopTemplate/types';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { usePreferenceSelector } from '~/hooks/api/preference';
import { PreferenceNames } from '~/store/features/api/resources/preference/constants';

const {
  api: { baseUrl: API_URL },
  ui: { phoenixUrl },
} = config;

export function navigateToLoopDetails(
  navigate: NavigateFunction,
  loopId: string,
  isOptedIntoLoopDetails: boolean
) {
  const loopDetailsLink = generateLoopDetailsLink(
    loopId,
    isOptedIntoLoopDetails
  );

  if (loopDetailsLink.startsWith('http')) {
    window.location.href = loopDetailsLink;
  } else {
    navigate(loopDetailsLink);
  }
}

export function generateLoopDetailsLink(
  loopId: string,
  isOptedIntoLoopDetails: boolean
) {
  return isOptedIntoLoopDetails
    ? `/loops/${loopId}/documents`
    : `${phoenixUrl}/my/loop/${loopId}`;
}

export function useLoopDetailsOptInPreference(defaultValue = false): boolean {
  const [optInLoopDetailsPreference] = usePreferenceSelector(
    PreferenceNames.NovaOptInLoopDetails
  );

  return optInLoopDetailsPreference === null
    ? false
    : optInLoopDetailsPreference?.value ?? defaultValue;
}

export function useLoopDetailsLink(loopId: string) {
  const isOptedIntoLoopDetails = useLoopDetailsOptInPreference();

  return generateLoopDetailsLink(loopId, isOptedIntoLoopDetails);
}

export function assignLoopToProfile(
  viewId: string,
  profileId: string
): Promise<any> {
  return makeVegaRequest('loops/actions/assignToProfile', {
    method: 'POST',
    body: JSON.stringify({
      profileId,
      viewId,
    }),
  });
}

export function addLoopTemplateToLoop(
  profileId: string,
  loopId: string,
  loopTemplateId: string
): Promise<any> {
  const url = `${API_URL}/loops/${loopId}`;
  const body = {
    data: {
      id: loopId,
      type: ResourceTypes.Loops,
      attributes: {
        loopTemplateId,
      },
    },
  };

  return makeJSONAPIRequest(url, {
    method: 'PATCH',
    headers: {
      [PROFILE_ID_HEADER]: profileId,
    },
    body: JSON.stringify(body),
  });
}

export type LoopTemplateType = {
  loopTemplateId: string;
} & Pick<LoopTemplateResource, Exclude<keyof LoopTemplateResource, 'id'>>;
export async function getProfileTemplates(
  profileId: string
): Promise<null | Array<LoopTemplateType>> {
  const options = {
    headers: {
      [PROFILE_ID_HEADER]: profileId,
    },
  };
  const templateResponse = await makeVegaRequest(
    ResourceTypes.LoopTemplates,
    options
  );

  if (templateResponse.payload?.length > 0) {
    return templateResponse.payload;
  }

  return null;
}

export async function fetchLoopDocumentsForClassSystemId(
  loopId: string,
  classSystemId: string
) {
  if (loopId && classSystemId) {
    const url = `${API_URL}/loops/${loopId}/relationships/documents?filter[classSystemId]=${classSystemId}`;
    const result = await makeJSONAPIRequest(url, {
      method: 'GET',
    });
    let documentIds: Array<string> = [];

    if (!!result && 'data' in result) {
      const data = result.data;

      if (Array.isArray(data)) {
        documentIds = data.map(r => r.id);
      }
    }

    return documentIds;
  }

  console.warn('Did not provide a valid loop id and class system id');

  return [];
}

export function inviteParticipantFullAccess(
  loopId: string,
  participantId: string
): Promise<any> {
  return makeVegaRequest('loops/actions/submitCollaborationInvitationRequest', {
    method: 'POST',
    body: JSON.stringify({
      loopId,
      participantId,
    }),
  });
}
